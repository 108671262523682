<template>
  <b-card
      class="warehouselocation-edit-wrapper"
  >
    <!-- form -->
    <b-form id="warehouselocationForm" class="edit-form mt-2">
      <b-row>
        <!--        <b-col md="3">-->
        <!--          <b-form-group-->
        <!--            label-cols="3"-->
        <!--            label-cols-lg="3"-->
        <!--            label="仓库ID"-->
        <!--            label-for="warehouse_id"-->
        <!--            label-size="sm"-->
        <!--            class="mb-1"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--              id="warehouse_id"-->
        <!--              size="sm"-->
        <!--              v-model="warehouselocation.warehouse_id"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="仓位库区"
              label-for="location_area"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="location_area"
                size="sm"
                v-model="warehouselocation.location_area"
                @change="onChange()"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="仓位列"
              label-for="location_column"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="location_column"
                size="sm"
                v-model="warehouselocation.location_column"
                @change="onChange()"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="仓位货架"
              label-for="location_frame"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="location_frame"
                size="sm"
                v-model="warehouselocation.location_frame"
                @change="onChange()"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="仓位储位"
              label-for="location_place"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="location_place"
                size="sm"
                v-model="warehouselocation.location_place"
                @change="onChange()"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="仓位全称"
              label-for="location_fullname"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="location_fullname"
                size="sm"
                v-model="locationFullname"
                readonly=""
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="状态"
              label-for="status"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                id="company_scale"
                :options="getCodeOptions('store_location_status')"
                :clearable="true"
                v-model="status"
                @input="changeSelect('status',$event)"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <!--        <b-col md="3">-->
        <!--          <b-form-group-->
        <!--            label-cols="3"-->
        <!--            label-cols-lg="3"-->
        <!--            label="启用"-->
        <!--            label-for="state"-->
        <!--            label-size="sm"-->
        <!--            class="mb-1"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--              id="state"-->
        <!--              size="sm"-->
        <!--              v-model="warehouselocation.state"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <b-col md="12">
          <div class="inner-card-title">附件</div>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="附件"
              label-for="attachments"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="warehouselocation.loaded"
                               :attachment_id="'attachments'"
                               :id="warehouselocation.attachments"
                               :object_type="'test'"
                               :object_id="0"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import warehouselocationStore from './warehouselocationStore'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      warehouselocation: ref({}),
      warehouseId: 0,
      status: {},
      locationFullname: "",
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('warehouselocation')) store.registerModule('warehouselocation', warehouselocationStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehouselocation')) store.unregisterModule('warehouselocation')
    })

    const edit = function () {
      store.dispatch('warehouselocation/edit', {id: this.id}).then(res => {
        this.warehouselocation = res.data.data
        this.warehouselocation.warehouse_id = this.warehouseId
        this.warehouselocation.state = 1
        if (this.warehouselocation.location_area === undefined || this.warehouselocation.location_area === "") {
          this.warehouselocation.location_area = ""
          this.warehouselocation.location_column = ""
          this.warehouselocation.location_frame = ""
          this.warehouselocation.location_place = ""
        }
        this.locationFullname = this.warehouselocation.location_fullname

        this.status = getCode('store_location_status', this.warehouselocation.status)
      })
    }

    const view = function () {
      store.dispatch('warehouselocation/view', {id: this.id}).then(res => {
        this.warehouselocation = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if (this.warehouselocation.location_area === undefined || this.warehouselocation.location_area === "") {
        toast.error("请填写仓位库区!")
        return false
      }
      if (this.warehouselocation.location_column === undefined || this.warehouselocation.location_column === "") {
        toast.error("请填写仓位列!")
        return false
      }
      if (this.warehouselocation.location_frame === undefined || this.warehouselocation.location_frame === "") {
        toast.error("请填写仓位货架!")
        return false
      }
      if (this.warehouselocation.location_place === undefined || this.warehouselocation.location_place === "") {
        toast.error("请填写仓位储位!")
        return false
      }
      if (this.warehouselocation.status === undefined || this.warehouselocation.status === "") {
        toast.error("请选择状态!")
        return false
      }
      this.warehouselocation.location_fullname = this.locationFullname
      store.dispatch('warehouselocation/save', this.warehouselocation).then(res => {
        console.log(res)
        if (res.data.code === 1) {
          toast.error(res.data.data)
        }else {
          toast.success('数据已保存!')
          this.$router.push({name: 'apps-warehouselocation-list', query: {id: this.warehouseId}});
        }
      })
    }

    const changeSelect = function (key, event) {
      this.warehouselocation[key] = event.value
    }

    const onChange = function () {
      this.locationFullname = this.warehouselocation.location_area + "-" + this.warehouselocation.location_column + "-" + this.warehouselocation.location_frame + "-" + this.warehouselocation.location_place
    }

    const onUploaded = function (id, attachment) {
      this.warehouselocation[id] = attachment.attachment_id
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      changeSelect,
      onChange,
      onUploaded,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.warehouseId = this.$route.query.warehouseId || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
